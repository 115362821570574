<template>
  <div>
    <p v-if="category === 'assets'">Your assets might include physical items like property, cars, jewellery and artwork, or other items like shares or bank accounts.</p>
    <p v-if="category === 'liabilities'">Before they distribute your assets, your Executor will need to pay off any debts. Debts can include your mortgage, personal loans, credit cards or informal loans from friends or family.</p>
    <notification-box :flat="true" type="info" class="mb-4">
      <div><strong>DO NOT</strong> include any sensitive information (eg. account numbers, passwords or PIN numbers).</div>
    </notification-box>
    <div v-if="local">
      <div>
        <h3 class="mb-4">Name of {{estateType}}</h3>
        <v-text-field
          outlined
          v-model="local.name"
          :error-messages="
            showErrors.name && errorMessages.name ? errorMessages.name : null
          "
        />
      </div>
      <div>
        <h3 class="mb-4">Location or Institution</h3>
        <p>Enter the location of the {{estateType}} or company the {{estateType}} is held with.</p>
        <v-text-field
          outlined
          v-model="local.institution"
          :error-messages="
            showErrors.institution && errorMessages.institution
              ? errorMessages.institution
              : null
          "
        />
      </div>
      <div class="mb-4">
        <h3 class="mb-4 text-capitalize">Details</h3>
        <p>Enter further details that may be relevant, if any.</p>
        <v-textarea
          outlined
          v-model="local.details"
          :error-messages="
            showErrors.details && errorMessages.details
              ? errorMessages.details
              : null
          "
        />
      </div>
      <div class="d-flex align-center">
        <v-btn text @click="close">{{ closeLabel }}</v-btn>
        <v-spacer />
        <v-btn v-bind="buttonType" depressed @click="submit">Save</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'

import NotificationBox from '../../../components/ui/NotificationBox'

export default {
  name: 'VaultFormsEstate',
  components: { NotificationBox },
  props: {
    closeLabel: {
      default: 'close'
    },
    id: {
      default() {
        return null
      }
    },
    category: {
      required: true
    }
  },
  data: () => ({
    local: null,
    showErrors: {
      name: false,
      details: false
    },
    processing: false
  }),
  mounted() {
    this.local = cloneDeep(this.estate)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.close()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch(`account/save_vault_${this.category}`, {
        id: this.id,
        estate: this.localFormatted
      })
    },
    close() {
      this.$emit('close')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    }
  },
  computed: {
    estate() {
      if (this.id)
        return this.$store.getters['account/vault'](this.category)[this.id]

      return {
        name: '',
        institution: '',
        details: ''
      }
    },
    estateType () {
      return this.category === 'assets' ? 'asset' : 'liability'
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        name: null,
        details: null
      }

      if (!this.localFormatted.name) {
        msgs.name = 'Required field'
      }

      if (this.localFormatted.details?.length > 100000) {
        msgs.details = 'Maximum length of 100,000 characters'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.estate)
    },
    localFormatted() {
      return {
        name: this.local.name.trim(),
        institution: this.local.institution?.trim() || '',
        details: this.local.details?.trim() || ''
      }
    }
  }
}
</script>

<style lang="scss"></style>
